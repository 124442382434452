@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?1r19mw");
  src: url("../assets/fonts/icomoon.eot?1r19mw#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?1r19mw") format("truetype"),
    url("../assets/fonts/icomoon.woff?1r19mw") format("woff"),
    url("../assets/fonts/icomoon.svg?1r19mw#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-x:before {
  content: "\e902";
}

.icon-locked:before {
  content: "\e904";
}

.icon-newspaper:before {
  content: "\e905";
}

.icon-pencil:before {
  content: "\e906";
}

.icon-user-tie:before {
  content: "\e976";
}

.icon-bin:before {
  content: "\e9ac";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-arrow-left2:before {
  content: "\ea40";
}

.icon-search:before {
  content: "\e901";
}

.icon-bars:before {
  content: "\e900";
}
